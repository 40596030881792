// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDU0Ca1Sjf2cSpggsW2xgfLajCIJgRosn4",
    authDomain: "reel-pay-web.firebaseapp.com",
    projectId: "reel-pay-web",
    storageBucket: "reel-pay-web.appspot.com",
    messagingSenderId: "618242893384",
    appId: "1:618242893384:web:a4b494420f3303e2bb9a7d",
    measurementId: "G-WX13W71SF7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
          <ThemeSettings>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
          </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
