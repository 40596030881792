import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
// @mui
import {styled} from '@mui/material/styles';
import {Box, Stack, Typography} from '@mui/material';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import {UploadIllustration} from "../../assets";

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({theme}) => ({
    outline: 'none',
    overflow: 'hidden',
    position: 'relative',
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': {opacity: 0.72, cursor: 'pointer'},
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadSingleFile({error = false, file, helperText, sx, ...other}) {
    const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
        multiple: false,
        ...other,
    });

    return (
        <Box sx={{width: '100%', ...sx}}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && {opacity: 0.72}),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    })
                }}
            >
                <input {...getInputProps()} />

                {!file && (
                    <BlockContent/>
                )}

                {file && (
                    <Stack
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
                    >

                      <Box sx={{ p: 2 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {file.path ?
                                <>Your file has been uploaded: <b>{file.path}</b></>
                            :
                                <>Your file was previously uploaded: <b>{file.path}</b>

                                    Click&nbsp;
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        sx={{ color: 'primary.main', textDecoration: 'underline' }}
                                    >
                                        here
                                    </Typography>
                                    &nbsp;to upload a new document.

                                </>
                            }
                        </Typography>
                      </Box>
                    </Stack>
                )}
            </DropZoneStyle>

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections}/>}

            {helperText && helperText}
        </Box>
    );
}
