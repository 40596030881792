import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="150%" height="150%" viewBox="0 0 281.000000 140.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,140.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
          <path d="M908 1376 c-96 -34 -209 -127 -309 -252 -46 -58 -129 -190 -129 -206
0 -7 185 -114 208 -120 8 -2 25 35 47 102 51 151 180 410 205 410 20 0 9 -30
-49 -142 -70 -134 -123 -273 -145 -380 -9 -42 -16 -83 -16 -91 0 -9 21 -19 56
-26 45 -10 66 -22 101 -57 l44 -44 25 29 c72 86 240 104 331 35 31 -23 34 -24
57 -9 29 19 32 27 41 91 12 81 15 84 101 84 l75 0 110 107 110 107 -108 52
c-59 28 -236 113 -392 188 -156 75 -293 136 -305 136 -11 -1 -37 -7 -58 -14z
m198 -127 l34 -20 -25 -50 -25 -49 -40 20 c-22 11 -40 23 -40 27 0 10 50 93
57 93 3 0 21 -9 39 -21z m-302 -28 c9 -14 -39 -52 -57 -45 -23 8 -21 20 5 38
25 18 44 20 52 7z m-95 -100 c11 -7 10 -13 -4 -35 -18 -27 -31 -31 -54 -17
-12 8 -12 13 3 35 17 27 32 32 55 17z m311 -31 l40 -20 -27 -54 -26 -55 -39
20 c-21 11 -38 22 -38 25 0 9 42 104 47 104 2 0 22 -9 43 -20z m-395 -86 c24
-15 24 -16 7 -52 -9 -20 -17 -38 -18 -40 -3 -5 -64 27 -64 33 0 6 44 75 48 75
1 0 14 -7 27 -16z m351 -124 c-2 -14 -9 -37 -15 -53 l-11 -28 -40 21 c-22 11
-40 22 -40 23 0 2 7 24 16 49 l15 46 40 -16 c34 -15 39 -21 35 -42z m-80 -127
c33 -16 35 -20 30 -53 -3 -19 -6 -41 -6 -47 0 -10 -6 -10 -27 -1 -48 20 -54
31 -47 76 4 23 8 42 10 42 2 0 20 -8 40 -17z"/>
          <path d="M1844 1010 l-212 -210 68 0 67 0 119 123 c66 67 132 139 148 159 l28
37 281 -141 c155 -77 284 -143 287 -146 3 -2 -32 -39 -77 -82 -46 -42 -83 -80
-83 -82 0 -3 11 -3 25 0 19 3 27 -1 33 -18 8 -22 15 -17 115 75 107 97 126
127 95 153 -13 9 -673 342 -680 342 -2 0 -98 -95 -214 -210z"/>
          <path d="M1419 768 c-17 -59 -94 -475 -89 -481 4 -4 36 -7 71 -5 l64 3 46 235
c26 129 45 238 42 243 -4 6 -132 11 -134 5z"/>
          <path d="M96 729 c-5 -16 -86 -423 -86 -433 0 -3 33 -6 74 -6 l75 0 6 43 c4
24 10 49 15 56 14 24 44 6 74 -47 l30 -52 73 0 c40 0 73 3 73 7 0 3 -13 30
-30 60 -16 29 -30 56 -30 60 0 5 14 14 31 22 74 33 111 164 63 228 -43 57 -76
67 -226 71 -110 4 -139 2 -142 -9z m236 -134 c9 -19 8 -29 -6 -50 -14 -22 -24
-25 -70 -25 l-53 0 10 50 10 50 49 0 c42 0 50 -3 60 -25z"/>
          <path d="M1599 516 l-46 -226 29 0 c32 0 32 1 48 83 l11 57 97 0 c73 0 107 5
138 19 118 54 135 217 28 271 -19 9 -70 16 -145 18 l-115 4 -45 -226z m254
163 c78 -21 82 -126 8 -176 -29 -20 -47 -23 -124 -23 -69 0 -88 3 -84 13 3 7
13 51 22 98 9 46 19 87 22 92 6 11 112 8 156 -4z"/>
          <path d="M665 641 c-85 -22 -150 -84 -160 -156 -9 -54 -1 -102 21 -134 24 -33
110 -71 165 -71 46 0 135 25 144 41 3 4 -6 26 -21 48 -22 34 -29 39 -44 31
-42 -23 -130 -7 -130 22 0 4 56 8 124 8 l124 0 4 47 c5 63 -24 120 -72 144
-42 21 -114 30 -155 20z m83 -103 c26 -26 13 -38 -43 -38 -59 0 -64 4 -38 32
18 20 63 24 81 6z"/>
          <path d="M1075 639 c-94 -23 -155 -100 -155 -196 0 -90 67 -152 176 -161 52
-4 147 19 160 39 3 5 -8 27 -23 49 -22 29 -33 36 -44 29 -35 -22 -129 -6 -129
23 0 4 56 8 125 8 l125 0 0 56 c0 34 -6 67 -17 84 -33 57 -135 89 -218 69z
m93 -101 c26 -26 13 -38 -43 -38 -59 0 -64 4 -38 32 18 20 63 24 81 6z"/>
          <path d="M2115 616 c-124 -54 -156 -243 -53 -306 62 -38 99 -36 200 8 5 2 8
-4 8 -13 0 -12 7 -15 26 -13 26 3 27 8 60 168 19 91 34 166 34 168 0 1 -13 2
-29 2 -24 0 -31 -5 -36 -29 -6 -30 -6 -30 -26 -10 -40 40 -124 51 -184 25z
m156 -65 c23 -23 29 -38 29 -71 0 -86 -52 -140 -137 -140 -70 0 -103 29 -103
91 0 88 56 149 138 149 35 0 49 -6 73 -29z"/>
          <path d="M2461 558 c10 -40 30 -117 43 -170 l23 -98 -24 -35 c-27 -40 -64 -53
-105 -37 -24 9 -30 8 -39 -11 -16 -28 11 -47 66 -47 76 0 79 3 268 303 105
165 106 167 78 167 -25 0 -36 -13 -112 -134 -46 -74 -86 -132 -89 -130 -3 3
-19 62 -36 132 l-30 127 -31 3 -31 3 19 -73z"/>
          <path d="M1936 265 c-80 -52 -264 -147 -319 -165 -36 -11 -40 -10 -210 79
l-172 92 -48 -12 c-59 -14 -73 -4 191 -143 200 -105 204 -107 240 -95 76 24
352 174 422 228 l24 19 -24 21 c-14 11 -27 20 -30 20 -3 1 -36 -20 -74 -44z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
