import PropTypes from 'prop-types';
// @mui
import {styled} from '@mui/material/styles';
import {Box, Link, Typography, Avatar} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavbarProduction.propTypes = {
    isCollapse: PropTypes.bool,
    production: PropTypes.object
};

export default function NavbarProduction({isCollapse, production}) {

    if(!production){
        return (<></>);
    }

    return (
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: 'transparent',
                    }),
                }}
            >
                <Avatar src={production.header_image} alt={production.name}/>

                <Box
                    sx={{
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                duration: theme.transitions.duration.shorter,
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0,
                        }),
                    }}
                >
                    <Typography variant="subtitle2" noWrap>
                        {production.name}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{color: 'text.secondary'}}>
                        <Link href={'/'}>Switch</Link>
                    </Typography>
                </Box>
            </RootStyle>
    );
}
