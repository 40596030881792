import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/production')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// ['admin','production','production-min','accounts','accounts']

// element: <Navigate to="/" replace />,

export default function Router() {
  return useRoutes([
    // Authentication Routes
    {
      path: 'auth',
      children: [
        { path: 'login', element: <GuestGuard><Login /></GuestGuard> },
        { path: 'register', element: <GuestGuard><Register /></GuestGuard> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: '/',
      element: <AuthGuard><ProductionList /></AuthGuard>,
    },
    {
      path: '/form/:production_id/:uuid/:page',
      element: <CrewForm />,
    },
    {
      path: '/production',
      element: <AuthGuard><DashboardLayout /></AuthGuard>,
      children: [
        {
          path: ':production_id/crew',
          element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'production-min', 'accounts', 'accounts-min', 'department_head', 'viewer']}>
              <CrewList />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':production_id/crew/add', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'department_head']}>
              <CrewAdd /> </RoleBasedGuard>)
        },

        {
          path: ':production_id/crew/:crew_id', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'production-min', 'accounts', 'accounts-min', 'department_head', 'viewer']}>
              <CrewDetailsView /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/crew/:crew_id/view', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'production-min', 'department_head', 'viewer']}>
              <CrewDetails /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/payroll', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'production-min', 'accounts', 'accounts-min', 'department_head', 'viewer']}>
              <PayrollList /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/payroll/:payroll_id', element:
            (<RoleBasedGuard hasContent roles={['admin', 'production', 'production-min', 'accounts', 'accounts-min', 'department_head', 'viewer']}>
              <PayrollStatus /> </RoleBasedGuard>)
        },

        {
          path: ':production_id/contract', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'production-min', 'department_head', 'viewer']}>
              <ContractList /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/contract/:contract_id/:page', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production', 'viewer']}>
              <ContractDetails /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/crew_database', element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <CrewDatabaseList /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/crew_database/:contract_id', element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <CrewDatabaseDetails /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/timecard', element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <TimecardList /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/timecard/:timecard_id', element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <TimecardDetails /> </RoleBasedGuard>)
        },
        {
          path: ':production_id/timecard/add', element: (
            <RoleBasedGuard hasContent roles={['admin', 'production']}>
              <TimecardAdd /> </RoleBasedGuard>)
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD
const ProductionList = Loadable(lazy(() => import('../pages/ProductionList')));
const CrewForm = Loadable(lazy(() => import('../pages/CrewForm')));
const CrewAdd = Loadable(lazy(() => import('../pages/production/CrewAdd')));
const CrewList = Loadable(lazy(() => import('../pages/production/CrewList')));
const CrewDetails = Loadable(lazy(() => import('../pages/production/CrewDetails')));
const CrewDetailsView = Loadable(lazy(() => import('../pages/production/CrewDetailsView')));

const PayrollList = Loadable(lazy(() => import('../pages/production/PayrollList')));
const PayrollStatus = Loadable(lazy(() => import('../pages/production/PayrollDetails')));
const ContractList = Loadable(lazy(() => import('../pages/production/ContractList')));
const ContractDetails = Loadable(lazy(() => import('../pages/production/ContractDetails')));

const CrewDatabaseList = Loadable(lazy(() => import('../pages/production/CrewDatabaseList')));

const CrewDatabaseDetails = Loadable(lazy(() => import('../pages/production/CrewDatabaseDetails')));

const TimecardList = Loadable(lazy(() => import('../pages/production/TimecardList')));

const TimecardDetails = Loadable(lazy(() => import('../pages/production/TimecardDetails')));

const TimecardAdd = Loadable(lazy(() => import('../pages/production/TimecardAdd')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
