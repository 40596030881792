import axios from 'axios';
import {axiosInstance} from "./axios";

const _ = require('lodash');

export const getProductions = async () =>  {
    const {data} = await axiosInstance.get(`/productions`);
    return data;
};

export const getProductionsSummary = async () =>  {
    const {data} = await axiosInstance.get(`/productions/summary`);
    return data;
};

export const getProduction = async (id) =>  {
    const {data} = await axiosInstance.get(`/productions/${id}`);
    return data;
};
