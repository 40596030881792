import {Outlet} from 'react-router-dom';
import PropTypes from "prop-types";
// @mui
import {Box, Grid, Stack, Toolbar} from "@mui/material";
import {styled} from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import AccountPopover from "./dashboard/header/AccountPopover";


// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0)
    }
}));

export default function LogoOnlyLayout() {
    return (
        <>
            <HeaderStyle>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={3}>
                        <Logo sx={{ width: 80, height: 80 }}/>
                    </Grid>
                </Grid>
            </HeaderStyle>
            <Outlet/>
        </>
    );
}
