import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import { getProduction } from '../../../api/production-api';
import SvgIconStyle from '../../../components/SvgIconStyle';
import CollapseButton from './CollapseButton';
import NavbarProduction from './NavbarProduction';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  calendar: getIcon('ic_calendar'),
};

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const { production_id } = useParams();
  const [production, setProduction] = useState();
  const [loading, setLoading] = useState(false);

  const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'Production',
      items: [
        {
          title: 'Crew',
          path: `/production/${production_id}/crew`,
          icon: ICONS.analytics,
          roles: ['admin', 'production', 'production-min', 'viewer'],
        },
        { title: 'Payroll', path: `/production/${production_id}/payroll`, icon: ICONS.dashboard, roles: ['admin'] },
        {
          title: 'Contracts',
          path: `/production/${production_id}/contract`,
          icon: ICONS.ecommerce,
          roles: ['admin', 'production', 'production-min', 'viewer'],
        },
        // {
        //   title: 'Crew Database',
        //   path: `/production/${production_id}/crew_database`,
        //   icon: ICONS.booking,
        //   roles: ['admin', 'production'],
        // },
        // {
        //   title: 'Timecards',
        //   path: `/production/${production_id}/timecard`,
        //   icon: ICONS.calendar,
        //   roles: ['admin', 'production'],
        // },
      ],
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    /* {
          subheader: 'management',
          items: [
            {
              title: 'user',
              path: '/production/user',
              icon: ICONS.user,
              roles: ['admin'],
              children: [
                { title: 'Four', path: '/production/user/four',  roles: ['admin']},
                { title: 'Five', path: '/production/user/five', roles: ['admin'] },
                { title: 'Six', path: '/production/user/six', roles: ['admin'] },
              ],
            },
          ],
        }, */
  ];

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getProductionDetails = async () => {
    try {
      const data = await getProduction(production_id);
      setProduction(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getProductionDetails();
      setLoading(false);
    })();
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        <NavbarProduction isCollapse={isCollapse} production={production} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
